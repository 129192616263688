@-webkit-keyframes up-in {
    from {
      opacity: 0;
      bottom: 0%;
    }
  
    to {
      opacity: 1;
      bottom: 50%;
    }
  }
  
  @keyframes up-in {
    from {
      opacity: 0;
      bottom: 0%;
    }
  
    to {
      opacity: 1;
      bottom: 50%;
    }
  }
  
  .logo {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    padding: 15px;
  }
  
  h3 a {
    font-weight: 600;
    text-decoration: none;
    border: 2px solid #333;
    line-height: 40px;
    padding: 0 20px;
    max-width: 100px;
    display: block;
    text-align: center;
    color: #fff;
    background: #ad5389;
  
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3c1053, #ad5389);
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3c1053, #ad5389);
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
  p {
    font-weight: 500;
    font-size: 30px;
    max-width: 600px;
    margin: 0;
  }

  .text-center {
    text-align:center;
  }

  .d-block {
    display:block;
  }

  .w-100 {
    width:100%;
  }

  .white {
    color:#fff;
  }
  
  .fill {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
  }
  
  body, html {
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Raleway', sans-serif;
  }
  
  .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: auto;
    background-color: white;
    position: relative;
    background: #355C7D;
  
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #C06C84, #6C5B7B, #355C7D);
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #C06C84, #6C5B7B, #355C7D);
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    &.no-scroll {
      overflow: hidden;
    }
  }
  
  .page {
    width: 80%;
    padding: 10px;
  }
  
  @media (max-width: 600px) {
    .page {
      width: 90%;
    }
  }
  
  .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  
    .grid-item {
      width: 33%;
      position: relative;
  
      &:after {
        content: '';
        display: block;
        margin-top: 100%;
      }
    }
  }
  
  @media (max-width: 600px) {
    .grid .grid-item {
      width: 50%;
    }
  }
  
  .box {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
    cursor: pointer;
    width: 600px;
    max-height: 400px;
    max-width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: inset 0px 0px 300px -12px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: inset 0px 0px 300px -12px rgba(0, 0, 0, 0.37);
    box-shadow: inset 0px 0px 300px -12px rgba(0, 0, 0, 0.37);
  
    &:hover {
      -webkit-box-shadow: inset 0px 0px 300px -200px rgba(0, 0, 0, 0.37);
      -moz-box-shadow: inset 0px 0px 300px -200px rgba(0, 0, 0, 0.37);
      box-shadow: inset 0px 0px 300px -200px rgba(0, 0, 0, 0.37);
    }
  
    img {
      width: 100%;
      transition: -webkit-transform 0.6s ease;
      transition: transform 0.6s ease;
      transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    }
  
    &.selected {
      opacity: 0;
    }
  
    &.on-top {
      cursor: default;
      transition: all 0.4s ease;
      box-shadow: 2px 2px 19px -2px rgba(0, 0, 0, 0.44);
      max-height: 330px;
    }
  
    &.image-out img {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }
  
  .content {
    position: absolute;
    padding: 20px 40px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .show .content {
    opacity: 1;
  }
  
  @media (max-width: 600px) {
    .content {
      padding: 10px 20px;
    }
  }
  
  .scroller {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
  
    h1 {
      color: white;
      width: 100%;
      margin-bottom: 30px;
      position: absolute;
      bottom: 50%;
      text-align: center;
      -webkit-animation: up-in 1s ease;
      animation: up-in 1s ease;
    }
  }
  
  .top-up {
    &.ng-hide-add, &.ng-hide-remove {
      transition: 0s ease top;
    }
  
    &.ng-hide-add-active, &.ng-hide-remove-active {
      transition: 0.6s ease top;
    }
  
    &.ng-hide-add {
      top: 0;
  
      &.ng-hide-add-active {
        top: 100%;
      }
    }
  
    &.ng-hide-remove {
      top: 100%;
  
      &.ng-hide-remove-active {
        top: 0;
      }
    }
  }
  
  .fullscreen-background {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #333;
    transition: top 0.5s ease;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-attachment: fixed;
  
    &.show {
      top: 0;
    }
  }
  
  .close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    color: white;
    cursor: pointer;
  
    i {
      font-size: 35px;
      background: #333;
      border-radius: 100px;
      padding: 5px;
    }
  
    &:hover {
      color: #ddd;
    }
  }

  .logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:30px;
    img {
      filter: saturate(100%);
    }
  }

  .copyright {
    margin-bottom: 60px;
  }